<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New Sales</h2>
    <form [formGroup]="siteEnggForm">
      <div class="form-row">
       
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
          <label for="companyFrom">Company From<span class="requried-field">*</span></label>
          <select
          id="companyFrom"
          name="companyFrom"
          class="form-control"
          formControlName="companyFrom"
          [ngClass]="{ 'is-invalid': submitted && f['companyFrom'].errors }"
        >
        <option value="" selected disabled>--Please select--</option>
          <option
            *ngFor="let data of companyName"
            [value]="data"
            >{{ data }}
          </option>
        </select>
          <div *ngIf="submitted && f['companyFrom'].errors" class="invalid-feedback">
            <div *ngIf="f['companyFrom'].errors['required']"> Name is required</div>
          </div>
        </div>
        <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="productName">Product Name<span class="requried-field">*</span></label>
            <ng-autocomplete
            [data]="productName"
            [searchKeyword]="keyword1"
            placeholder="Enter the  Name"
            (selected)='selectEvent1($event)'
            (inputChanged)='onChangeSearch1($event)'
            (inputFocused)='onFocused1($event)'
            historyIdentifier="productName"
            [itemTemplate]="itemTemplate2"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate2 let-item>
            <a [innerHTML]="item.productName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
       
            <div *ngIf="submitted && f['productName'].errors" class="invalid-feedback">
              <div *ngIf="f['productName'].errors['required']">Name is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-12 col-lg-12">
            <label for="companyTo">Company To<span class="requried-field">*</span></label>
            <select
            id="companyTo"
            name="companyTo"
            class="form-control"
            formControlName="companyTo"
            [ngClass]="{ 'is-invalid': submitted && f['companyTo'].errors }"
          >
          <option value="" selected disabled>--Please select--</option>
            <option
              *ngFor="let data of companyName"
              [value]="data"
              >{{ data }}
            </option>
          </select>
            <div *ngIf="submitted && f['companyTo'].errors" class="invalid-feedback">
              <div *ngIf="f['companyTo'].errors['required']"> Name is required</div>
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="date">Date<span class="requried-field">*</span></label>
            <input
            type="date"
            id="date"
            placeholder="Enter date"
            class="form-control"
            formControlName="date"
            [ngClass]="{ 'is-invalid': submitted && f['date'].errors }"
          />
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="quantity">Qunatity</label>
            <input
              type="number"
              id="quantity"
              placeholder="Enter quantity"
              class="form-control"
              formControlName="quantity"
              [ngClass]="{ 'is-invalid': submitted && f['quantity'].errors }"
            />
            <div *ngIf="submitted && f['quantity'].errors" class="invalid-feedback">
              <div *ngIf="f['quantity'].errors['required']">Quantity is required</div>
              
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="productPrice">Product Price<span class="requried-field">*</span></label>
            <input
              type="number"
              id="productPrice"
              placeholder="Enter productPrice"
              class="form-control"
              formControlName="productPrice"
              [ngClass]="{ 'is-invalid': submitted && f['productPrice'].errors }"
            />
            <div *ngIf="submitted && f['productPrice'].errors" class="invalid-feedback">
              <div *ngIf="f['productPrice'].errors['required']">productPrice is required</div>
            </div>
          </div>
    
          <div class="form-group col-sm-12 col-md-4">
            <label for="salePrice">Sale Price</label>
            <input
              type="number"
              id="salePrice"
              placeholder="Enter salePrice"
              class="form-control"
              formControlName="salePrice"
              [ngClass]="{ 'is-invalid': submitted && f['salePrice'].errors }"
            />
            <div *ngIf="submitted && f['salePrice'].errors" class="invalid-feedback">
              <div *ngIf="f['salePrice'].errors['salePrice']">Sale is required</div>
              
            </div>
          </div>
          <div class="form-group col-sm-12 col-md-4">
            <label for="otherDetails">Other details</label>
            <textarea
              type="text"
              id="otherDetails"
              placeholder="Enter otherDetails"
              class="form-control"
              formControlName="otherDetails"
              [ngClass]="{ 'is-invalid': submitted && f['otherDetails'].errors }"
            >
            </textarea>
            <div *ngIf="submitted && f['otherDetails'].errors" class="invalid-feedback">
              <div *ngIf="f['otherDetails'].errors['required']">Other Details is required</div>
              
            </div>
          </div>
      </div>
      <button type="submit" class="btn btn-primary" (click)="onSubmit(siteEnggForm.value)">Submit</button>
      </form>
      </div>