import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AddComponent } from "./add/add.component";
import { EditComponent } from "./edit/edit.component";
import { SalesComponent } from "./sales.component";





const routes: Routes = [
  {
    path: "",
    component: SalesComponent
  },
  {
    path: "add",
    component: AddComponent
  },
  {
    path: "edit",
    component: EditComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule {}
